var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("multiselect", {
    attrs: {
      placeholder: "Select Pin",
      label: "id",
      "track-by": "image",
      "close-on-select": true,
      "group-select": false,
      multiple: false,
      "option-height": 30,
      options: _vm.options,
      searchable: false,
      "show-labels": false
    },
    on: { input: _vm.onInput },
    scopedSlots: _vm._u([
      {
        key: "singleLabel",
        fn: function(props) {
          return [
            props.option.id
              ? _c("img", {
                  staticClass: "option__image image is-32x32",
                  attrs: {
                    src: _vm.route("pins.show", props.option.image),
                    alt: props.option.id
                  }
                })
              : _c("span", { staticClass: "option__title" }, [
                  _vm._v(" " + _vm._s(_vm.i18n("Select pin")) + " ")
                ])
          ]
        }
      },
      {
        key: "option",
        fn: function(props) {
          return [
            props.option.id
              ? _c("figure", { staticClass: "image is-32x32 margin-right-8" }, [
                  _c("img", {
                    attrs: {
                      alt: props.option.id,
                      src: _vm.route("pins.show", props.option.image)
                    }
                  })
                ])
              : _vm._e()
          ]
        }
      }
    ]),
    model: {
      value: _vm.pinSelect,
      callback: function($$v) {
        _vm.pinSelect = $$v
      },
      expression: "pinSelect"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }