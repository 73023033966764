export default {
    data: () => ({
        lat: null,
        lng: null,
    }),
    methods: {
        updateCoordinates(event) {
            this.form.field('lat').value = event.lat();
            this.form.field('lng').value = event.lng();
        },
        setPlace(place) {
            this.lat = place.geometry.location.lat();
            this.lng = place.geometry.location.lng();
            this.form.field('lat').value = place.geometry.location.lat();
            this.form.field('lng').value = place.geometry.location.lng();
        },
    },
};
