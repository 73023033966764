<template>
    <gmap-autocomplete
        class="introInput"
        :options="{fields: ['geometry', 'formatted_address', 'address_components', 'name']}"
        v-bind="$attrs"
        v-on="$listeners">
        <template #input="slotProps">
            <b-field class="is-100">
                <b-input icon-pack="fas"
                         ref="input"
                         icon="map-marker-alt"
                         data-test="gmap-autocomplete"
                         @listeners="slotProps.listeners"
                         @attrs="slotProps.attrs"/>
                <p class="control">
                    <b-button type="is-danger" icon-right="times-circle" @click="resetValues"/>
                </p>
            </b-field>
        </template>
    </gmap-autocomplete>
</template>

<script>
export default {
    name: 'BGmapAutocomplete',
    inheritAttrs: false,
    methods: {
        resetValues() {
            this.$emit('place_changed',
                {
                    geometry: {
                        location: { lat() { return null; }, lng() { return null; } },
                    },
                });
        },
    },
};
</script>
