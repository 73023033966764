<template>
    <multiselect v-model="pinSelect"
        placeholder="Select Pin"
        label="id"
        track-by="image"
        v-bind:close-on-select="true"
        v-bind:group-select="false"
        v-bind:multiple="false"
        v-bind:option-height="30"
        v-bind:options="options"
        v-bind:searchable="false"
        v-bind:show-labels="false"
        @input="onInput">
        <template slot="singleLabel" slot-scope="props">
            <img v-if="props.option.id"
                 class="option__image image is-32x32"
                 :src="route('pins.show', props.option.image)"
                 :alt="props.option.id">
            <span v-else class="option__title">
                {{ i18n('Select pin') }}
            </span>
        </template>
        <template v-slot:option="props">
            <figure v-if="props.option.id" class="image is-32x32 margin-right-8">
                <img :alt="props.option.id" :src="route('pins.show', props.option.image)">
            </figure>
        </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
    name: 'PinSelect',
    inject: ['i18n', 'locale', 'route'],
    components: { Multiselect },
    props: {
        value: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        options: [],
        pinSelect: {},
    }),
    computed: {
        localeLabel() {
            return `name_${this.locale}`;
        },
    },
    mounted() {
        this.fetchOptions();
    },
    methods: {
        fetchOptions() {
            axios.get(this.route('pins.options')).then(response => {
                this.options = response.data;
                if (this.value) {
                    this.setPinValue();
                }
            }).catch(this.errorHandler);
        },
        onInput() {
            this.$emit('input', this.pinSelect);
        },
        renderLabel(option) {
            if (option.$isLabel !== undefined) {
                return option.$groupLabel;
            }
            return option.id;
        },
        setPinValue() {
            this.pinSelect = this.options.filter(option => option.id === this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
    .image.is-32x32 {
        display: inline-block !important;
        vertical-align: middle;
    }
    .option-label {
        vertical-align: middle;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 30px;
        margin-left: 10px;
    }
</style>

<style lang="scss">
    .label{
        font-size: 0.9rem;
    }
    .multiselect__placeholder {
        font-size: 1.0rem;
    }
    .multiselect__option--disabled {
        text-transform: capitalize !important;
    }
    .multiselect__option{
        width: 100%;
        display: inline-block !important;
        padding: 5px;
        vertical-align: middle;
    }
</style>
