var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gmap-autocomplete",
    _vm._g(
      _vm._b(
        {
          staticClass: "introInput",
          attrs: {
            options: {
              fields: [
                "geometry",
                "formatted_address",
                "address_components",
                "name"
              ]
            }
          },
          scopedSlots: _vm._u([
            {
              key: "input",
              fn: function(slotProps) {
                return [
                  _c(
                    "b-field",
                    { staticClass: "is-100" },
                    [
                      _c("b-input", {
                        ref: "input",
                        attrs: {
                          "icon-pack": "fas",
                          icon: "map-marker-alt",
                          "data-test": "gmap-autocomplete"
                        },
                        on: {
                          listeners: slotProps.listeners,
                          attrs: slotProps.attrs
                        }
                      }),
                      _c(
                        "p",
                        { staticClass: "control" },
                        [
                          _c("b-button", {
                            attrs: {
                              type: "is-danger",
                              "icon-right": "times-circle"
                            },
                            on: { click: _vm.resetValues }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        "gmap-autocomplete",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }